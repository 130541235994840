/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { Autocomplete, FormControl, FormHelperText, FormLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { apiget, apipost, apiput } from "../../service/api";

const Supplier = (props) => {
    const { open, handleClose, _id, setUserAction, data } = props
    const [leadData, setLeadData] = useState([])
    const [contactData, setContactData] = useState([])

    const userid = localStorage.getItem('user_id')
    const userRole = localStorage.getItem("userRole");

    // -----------  validationSchema
    const validationSchema = yup.object({
        status: yup.string().required("Supplier is required"),
    });

    // -----------   initialValues
    const initialValues = {
        status: "",
        createdBy: userid,
        lead_id: _id,
        contact_id: _id,
    };

    // add meeting api
    const addMeeting = async (values) => {
        const data = values;
        console.log('data',data);
        const result = await apipost('meeting/add', data)
        // setUserAction(result)

        if (result && result.status === 201) {
            formik.resetForm();
            handleClose();
            toast.success(result.data.message)
        }
        formik.resetForm();
        handleClose();
        toast.success('Supplier assigned successfully')
    }

    const editSupplier = async (values) => {
        console.log(props.id,'values',values);
        // return;
        // const data = values;
        const result = await apipost(`policy/editSupplier/${props.id}`, {'supplier' : values.status})
        if (result && result.status === 201) {
            formik.resetForm();
            handleClose();
            toast.success(result.data.message)
        }
        // setUserAction(result)
        // if (result && result.status === 200) {
        //     handleClose();
        //     fetchPolicy();
        // }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            addMeeting(values)
            resetForm();
        },
    });
    
    // lead api
    const fetchLeadData = async () => {
        const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
        if (result && result.status === 200) {
            setLeadData(result?.data?.result)
        }
    }

    // contact api
    const fetchContactData = async () => {
        const result = await apiget(userRole === 'admin' ? `contact/list` : `contact/list/?createdBy=${userid}`)
        if (result && result.status === 200) {
            setContactData(result?.data?.result)
        }
    }

    useEffect(() => {
        fetchLeadData();
        fetchContactData();
        // formik.values.meetingAttendes = data?.emailAddress
    }, [open, data])
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="sm" fullWidth
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: '100%'
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">Add Supplier </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item  xs={12} sm={12}>
                                    <FormLabel>Supplier Name</FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id=""
                                            name="status"
                                            label=""
                                            size="small"
                                            value={formik.values.status || null}
                                            onChange={formik.handleChange}
                                            error={formik.touched.status && Boolean(formik.errors.status)}
                                        >
                                            <MenuItem value="Test 1">Test 1</MenuItem>
                                            <MenuItem value="Test 2">Test 2</MenuItem>
                                            <MenuItem value="Test 3">Test 3</MenuItem>
                                            <MenuItem value="Test 4">Test 4</MenuItem>
                                            <MenuItem value="Test 5">Test 5</MenuItem>
                                            <MenuItem value="Test 6">Test 6</MenuItem>
                                            <MenuItem value="Test 7">Test 7</MenuItem>
                                            <MenuItem value="Test 8">Test 8</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.status && Boolean(formik.errors.status)
                                            }
                                        >
                                            {formik.touched.status && formik.errors.status}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                        color="secondary"
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                        color="error"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Supplier