/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import NotesIcon from "@mui/icons-material/Notes";
// import Addnotes from './AddNotes';
// import ViewNote from './ViewEdit';
import TableStyleTwo from './TableStyleTwo';
import Palette from '../theme/palette'

const Summary = ({ rows, toggleVisibilityNotes, isVisibleNotes, _id, setUserAction, method, policyData }) => {
    // let count = 0;
    const [openNoteView, setOpenNoteView] = useState(false);
    const [noteId, setNoteId] = useState('');
    const handleOpenNoteView = () => setOpenNoteView(true)
    const handleCloseNoteView = () => setOpenNoteView(false);

    const getTotalAmount = () => {
        let amount = " NA";
        if( policyData && policyData.policydocuments.length > 0 ){
            amount = policyData.policydocuments.reduce  ((acc, curr)=>{
                if(curr && curr.invoiceAmount){
                    return acc  + parseInt(curr.invoiceAmount, 10);
                }
                return acc;
            }, 0)
            return amount ? ` $ ${amount}` : '';
        }
       return amount;
    }
    const getEstimateAmount = () => {
        let amount = " NA";
        if( policyData && policyData.claims && policyData.claims.length > 0 ){
            amount = policyData.claims.reduce  ((acc, curr)=>{
                if(curr && curr.estimateAmount){
                    return acc  + parseInt(curr.estimateAmount, 10);
                }
                return acc;
            }, 0)
            return amount ? ` $ ${amount}` : '';
        }
       return amount;
    }
    const getApprovedAmount = () => {
        let amount = " NA";
        if( policyData && policyData.claims && policyData.claims.length > 0 ){
            amount = policyData.claims.reduce  ((acc, curr)=>{
                if(curr && curr.approvedAmount){
                    return acc  + parseInt(curr.approvedAmount, 10);
                }
                return acc;
            }, 0)
            return amount ? ` $ ${amount}` : '';
        }
       return amount;
    }
    // const columns = [
    //     {
    //         field: "subject",
    //         headerName: "Subject",
    //         cellClassName: "name-column--cell name-column--cell--capitalize",
    //         flex: 1,
    //         renderCell: (params) => {
    //             const handleFirstNameClick = () => {
    //                 handleOpenNoteView();
    //                 setNoteId(params?.row?._id)
    //             };

    //             return (
    //                 <Box onClick={handleFirstNameClick}>
    //                     {params.value}
    //                 </Box>
    //             );
    //         }
    //     },
    //     {
    //         field: "createdOn",
    //         headerName: "Date Created",
    //         flex: 1,
    //         valueFormatter: (params) => {
    //             const date = new Date(params.value);
    //             return date.toLocaleString();
    //         },
    //     },
    //     {
    //         field: "modifiedOn",
    //         headerName: "Date Modified",
    //         flex: 1,
    //         valueFormatter: (params) => {
    //             const date = new Date(params.value);
    //             return date.toLocaleString();
    //         },
    //     }

    // ];

    // open note model
    const [openNote, setOpenNote] = useState(false);
    const handleOpenNote = () => {
        setOpenNote(true);
    };
    const handleCloseNote = () => setOpenNote(false);

    const balanceSumInsured = () => {
        let amt = '';
        if(policyData.totalApprovedAmount > 0) {
            amt = policyData.totalAssuredAmount - policyData.totalApprovedAmount;
        } else if(policyData.totalEstimateAmount > 0) {
            amt = policyData.totalAssuredAmount - policyData.totalEstimateAmount;
        } else {
            amt = policyData.totalAssuredAmount;
        }

        return amt;
    }

    return (
        <div>
            {/* VIew model */}
            {/* <ViewNote open={openNoteView} handleClose={handleCloseNoteView} id={noteId} setUserAction={setUserAction} /> */}

            {/* Add Notes */}
            {/* <Addnotes open={openNote} handleClose={handleCloseNote} _id={_id} setUserAction={setUserAction} method={method} /> */}

            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityNotes}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleNotes ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <NotesIcon />
                            <Typography variant="h5">Estimate Summary</Typography>
                        </Stack>
                        {/* <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleNotes && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenNote}
                                >
                                    Create Note
                                </Button>
                            )}
                        </Stack> */}
                    </Stack>
                </Grid>
            </Box>
            {
                isVisibleNotes && policyData && policyData.claims && policyData.claims[0] && policyData.claims[0].estimateAmount  &&
                <TableStyleTwo >
                    <Box width="100%"  style={{marginLeft : '50px'}}>
                       
                        {/* <div style={{border : '1px solid'}}> */}
                        <Box p={3}>
                        <div style={{fontWeight: '700', fontSize : '20px',marginBottom: '20px' }}>
                            Sum Assured :  
                            {policyData && policyData.totalAssuredAmount ? ` $ ${policyData.totalAssuredAmount}` : 'NA'}
                        </div>
                        <div style={{fontWeight: '700', fontSize : '20px',marginBottom: '20px' }}>
                            Total Estimate : 
                            {policyData && policyData.totalEstimateAmount ? ` $ ${policyData.totalEstimateAmount}` : 'NA'}
                        </div>
                        {
                            <Grid container display="flex" spacing={2} style={{marginLeft : '20px', marginBottom : '20px'}}>
                            {
                                policyData && policyData.claims && policyData.claims.length > 0 && policyData.claims.map((val, index)=>{
                                    // if(val.invoiceAmount)
                                    //     count+=1;
                                    console.log('val',val)
                                    return(
                                        (val.estimateAmount) &&
                                        <Grid item xs={12} sm={12}>
                                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                                <Typography variant="body1">{val.estimateAmount && `Estimate ${index + 1}`}</Typography>
                                                <Typography variant="body2" color={Palette.grey[600]}>{val.estimateAmount && val.estimateAmount}</Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        }
                        <div style={{fontWeight: '700', fontSize : '20px',marginBottom: '20px' }}>
                            Total Claim Amount : 
                            {policyData && policyData.totalInvoiceAmount ? ` $ ${policyData.totalInvoiceAmount}` : 'NA'}
                        </div>
                       <div style={{fontWeight: '700', fontSize : '20px',marginBottom: '20px' }}>
                            Total Approved Amount : 
                            {policyData && policyData.totalApprovedAmount ? ` $ ${policyData.totalApprovedAmount}` : 'NA'}
                        </div>

                        <div style={{fontWeight: '700', fontSize : '20px',marginBottom: '20px' }}>
                            Balance Sum Insured : {balanceSumInsured()}
                        </div>
                       
                        
                        {/* {
                            getTotalAmount() &&
                            <div style={{fontWeight: '700', fontSize : '18px',marginTop: '20px'}}>
                                Claim Amount : 
                                {getTotalAmount()}
                            </div>
                        }
                         {
                            getApprovedAmount() &&
                            <div style={{fontWeight: '700', fontSize : '18px',marginTop: '20px'}}>
                                Approved Amount : 
                                {getApprovedAmount()}
                            </div>
                        } */}
                        </Box>
                       
                    </Box>
                </TableStyleTwo>

            }
        </div>
    )
}

export default Summary
