/* eslint-disable react/prop-types */
import * as React from "react";
import { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel,FormControl, Select, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { apipost } from "../../../service/api";

const AddUser = (props) => {
    const { open, handleClose, setUserAction, _id, selectedBenefits, policyData } = props
    const userid = localStorage.getItem('user_id');
    const [selectedBenefit, setSelectedBenefit] = useState({});
    // console.log("selectedBenefits ==============",selectedBenefits);

    // -----------  validationSchema
    const validationSchema = yup.object({
        file: yup.string().required("File is required"),
        // fileName: yup.string().required("File Name is required"),
        benefitId: yup.string().required("Benefit Name is required"),
        documentName: yup.string().required("Document Name is required"),
    });

    // -----------   initialValues
    const initialValues = {
        file: "",
        // fileName: "",
        documentName : "",
        benefitId : "",
        policy_id: _id,
        createdBy: userid
    };

    // add contact api
    const fileUpload = async (values) => {
        const data = new FormData()
        data.append("name", values.file.name)
        data.append("file", values.file)
        // data.append("fileName", values.fileName)
        data.append("documentName", values.documentName)
        data.append("benefitType", selectedBenefit.benefitType)
        data.append("policy_id", values.policy_id)
        data.append("createdBy", values.createdBy)
        data.append("customerBenefitId", selectedBenefit._id)
        data.append("benefitId", selectedBenefit.benefitId)

        const result = await apipost('policydocument/upload', data)
        setUserAction(result)

        if (result && result.status === 200) {
            formik.resetForm();
            handleClose();
            // toast.success(result.data.message)
        }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            fileUpload(values)
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">Add New </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Select Benefit</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="benefitId"
                                            name="benefitId"
                                            size="small"
                                            value={formik.values.benefitId}
                                            onChange={(e) => {
                                                formik.setFieldValue("benefitId", e.target.value);
                                                setSelectedBenefit(policyData.customerBenefits[e.target.value])
                                            }}
                                            error={formik.touched.benefitId && Boolean(formik.errors.benefitId)}
                                        >
                                            {policyData && policyData.customerBenefits && policyData.customerBenefits.length > 0 && policyData.customerBenefits.map((ele, index) => (
                                                <MenuItem key={ele._id} value={index}>{ele.benefitType}</MenuItem>
                                            ))
                                            }
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.benefitId && Boolean(formik.errors.benefitId)
                                            }
                                        >
                                            {formik.touched.benefitId && formik.errors.benefitId}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Sum Insured</FormLabel>
                                    <TextField
                                        id="benefitAmount"
                                        name="policyNumber"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        disabled
                                        value={selectedBenefit.amount}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Select Document Name</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="documentName"
                                            name="documentName"
                                            size="small"
                                            value={formik.values.documentName}
                                            onChange={formik.handleChange}
                                            error={formik.touched.documentName && Boolean(formik.errors.documentName)}
                                        >
                                            <MenuItem value="Doc 1">Doc 1</MenuItem>
                                            <MenuItem value="Doc 2">Doc 2</MenuItem>
                                            <MenuItem value="Doc 3">Doc 3</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.documentName && Boolean(formik.errors.documentName)
                                            }
                                        >
                                            {formik.touched.documentName && formik.errors.documentName}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Upload File</FormLabel>
                                <TextField
                                    id="file"
                                    name="file"
                                    size="small"
                                    maxRows={10}
                                    fullWidth
                                    type="file"
                                    multiple
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => {
                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                    error={
                                        formik.touched.file &&
                                        Boolean(formik.errors.file)
                                    }
                                    helperText={
                                        formik.touched.file && formik.errors.file
                                    }
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>FileName</FormLabel>
                                <TextField
                                    id="fileName"
                                    name="fileName"
                                    size="small"
                                    fullWidth
                                    value={formik.values.fileName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.fileName && Boolean(formik.errors.fileName)
                                    }
                                    helperText={formik.touched.fileName && formik.errors.fileName}
                                />
                            </Grid> */}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddUser