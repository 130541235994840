/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
// components
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AttachmentIcon from '@mui/icons-material/Attachment';
import Iconify from '../../../components/iconify';
// sections
import AddDocument from './Add'
import AddUser from './AddUser'
import { apidelete, apiget } from '../../../service/api';
import TableStyleTwo from '../../../components/TableStyleTwo';
import { constant } from '../../../constant';
// ----------------------------------------------------------------------

const PolicyDocumentPage = ({ rows, toggleVisibilityPolicyDoc, isVisiblePolicyDoc, setUserAction, _id, policyData, selectedBenefits }) => {
    const user = localStorage.getItem('userRole');
    const [openAdd, setOpenAdd] = useState(false);
    const [openAddUser, setOpenAddUser] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const getRows = () => {
        if(policyData && policyData.estimateDocuments && policyData.estimateDocuments.length > 0){
            console.log('===========>getRows', policyData.estimateDocuments)
            const estimate = policyData.estimateDocuments;
            // if(claims && claims.estimateDocuments && claims.estimateDocuments.length > 0){
            //     claims.estimateDocuments
            // }
        }
    }
    // const [role, setRole] = useState(userInfo.emailAddress === "rishabh@gmail.com" ? true : false)
    const email = userInfo.emailAddress === "rishabh@gmail.com";
    const handleOpenAdd = () => {
        if(user === "admin")
            setOpenAdd(true);
        else
            setOpenAddUser(true);
    }
    const handleCloseAdd = () => {
        setOpenAdd(false);
        setOpenAddUser(false);
    }
    const downloadFile = async (id) => {
        const result = await apiget(`claim/file/?path=${id}`)
        setUserAction(result)
    }

    const deleteFile = async (id) => {
        const result = await apidelete(`policydocument/delete/${id}`)
        setUserAction(result)
    }


    const columns = [
        // {
        //     field: "file",
        //     headerName: "File",
        //     flex: 1,
        // },
        {
            field: "claimId",
            headerName: "Estimate No",
            flex: 1,
            valueFormatter: (params) => {
                const claimId = params.value ? params.value.substring(params.value.length - 6) : "NA" ;
                return claimId;
            },
        },
        {
            field: "estimateAmount",
            headerName: "Reserved Amount",
            flex: 1,
        },
        {
            field: "fileName",
            headerName: "File Name",
            flex: 1,
            cellClassName: "name-column--cell--capitalize" 
        }, {
            field: "invoiceAmount",
            headerName: "Invoice Amount",
            flex: 1,
            valueFormatter: (params) => {
                const date = params.value ? params.value : "NA" ;
                return date;
            },
        },
        {
            field: "createdOn",
            headerName: "CreateOn",
            flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toDateString();
            },

        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const handleFirstNameClick = async () => { downloadFile(params.row.path) };
                const downloadUrl = `${constant.baseUrl}claim/file/?path=${params.row.path}`;

                return (
                    <Box onClick={handleFirstNameClick}>
                        <Stack direction={"row"} spacing={2}>
                            <a href={downloadUrl}><Button variant='contained' size='small'>Download</Button></a>
                            {/* <Button variant='outlined' size='small' color='error' onClick={() => deleteFile(params.row._id)}>Delete</Button> */}
                        </Stack>
                    </Box>
                );
            }
        },

    ];

    const column1 = [
        {
            field: "benefitType",
            headerName: "Benefit Type",
            flex: 1,
        },

        {
            field: "documentName",
            headerName: "Document Name",
            flex: 1,
            cellClassName: "name-column--cell--capitalize" 
        },
        {
            field: "createdOn",
            headerName: "CreateOn",
            flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toDateString();
            },

        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const handleFirstNameClick = async () => { downloadFile(params.row._id) };
                const downloadUrl = `${constant.baseUrl}policydocument/file/${params.row._id}`;

                return (
                    <Box onClick={handleFirstNameClick}>
                        <Stack direction={"row"} spacing={2}>
                            <a href={downloadUrl}><Button variant='contained' size='small'>Download</Button></a>
                            {/* <Button variant='outlined' size='small' color='error' onClick={() => deleteFile(params.row._id)}>Delete</Button> */}
                        </Stack>
                    </Box>
                );
            }
        },

    ];

    useEffect(()=> {
        getRows()
    },[])

    return (
        <>
            {/* Add Document Model */}
            <AddDocument open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} policyData={policyData}/>
            <AddUser open={openAddUser} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} selectedBenefits={selectedBenefits}  policyData={policyData}/>

            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityPolicyDoc}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisiblePolicyDoc ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">{user === "user" ? 'Policy Documents' : 'Claim Documents'}</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisiblePolicyDoc && !email && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Add Document
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisiblePolicyDoc &&
                <TableStyleTwo>
                    <Box width="100%" >
                        {
                            user === "admin" ? 
                            <DataGrid
                                rows={policyData && policyData.estimateDocuments && policyData.estimateDocuments.length > 0 ? policyData.estimateDocuments : []}
                                columns={columns}
                                getRowId={row => row._id}
                                columnHeaderHeight={40}
                            />
                            :
                            <DataGrid
                            rows={rows.length > 0 ? rows : []}
                            columns={column1}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                        />
                        }
                        
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default PolicyDocumentPage