/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-duplicates */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";

import { FormControl, FormHelperText, FormLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
// eslint-disable-next-line import/no-unresolved
import { apidelete, apiget, apiput } from "src/service/api";
import { useEffect } from "react";
import { useState } from "react";

const ViewEditEstimate = (props) => {
    const { open, handleClose, id, setUserAction, policyData, cliamIndex } = props


    const [claimDate, setClaimData] = useState({})

    // -----------  validationSchema
    const validationSchema = yup.object({
        policyNumber: yup.string().required("Policy Number is required"),
        benefitType: yup.string().required("Claim Type is required"),
        estimateAmount: yup.string().required("Reserved Amount is required"),
        estimateDate: yup.string().required("Estimate Date is required"),
        serviceDate: yup.string().required("Service Date is required"),
        country: yup.string().required("Country Date is required"),
        city: yup.string().required("City Date is required"),
        // claimStatus: yup.string().required("Claim Status is required"),
        supplierName: yup.string().required("Supplier Name is required"),
        currency: yup.string().required("Currency Name is required"),
        claimNotes: yup.string().required("Claim Notes is required"),
    });

    // -----------   initialValues
    const initialValues = {
        policyNumber: claimDate && claimDate._id ?  claimDate._id.substring(claimDate._id.length - 6) : "",
        benefitType: claimDate?.benefitType,
        estimateAmount: claimDate?.estimateAmount,
        estimateDate: claimDate?.estimateDate,
        claimNotes: claimDate?.claimNotes,
        serviceDate: claimDate?.serviceDate,
        supplierName: claimDate?.supplierName,
        currency: claimDate?.currency,
        estimateName: "",
        city: claimDate?.city,
        country: claimDate?.country

    };
    // console.log('initialValuesssssss',initialValues)
    // edit api
    const EditClaim = async (values) => {
        const data = values;
        const result = await apiput(`claim/edit/${id}`, data)
        setUserAction(result)
        if (result && result.status === 200) {
            handleClose();
        }
    }

    // delete api
    const deletedata = async () => {
        const result = await apidelete(`claim/delete/${id}`)
        setUserAction(result)
        handleClose();
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            const claimData = {
                policyNumber: values.policyNumber,
                benefitType: values.benefitType,
                estimateAmount: values.estimateAmount,
                estimateDate: values.estimateDate,
                claimNotes: values.claimNotes,
                serviceDate: values.serviceDate,
                supplierName: values.supplierName,
                currency: values.currency,
                estimateName: values.estimateName,
                city: values.city,
                country: values.country
            }
            EditClaim(claimData)
            resetForm();
        },
    });

    // fetch api
    const fetchdata = async () => {
        if (id) {
            const result = await apiget(`claim/view/${id}`)
            if (result && result.status === 200) {
                setClaimData(result?.data)
            }
        }
    }


    useEffect(() => {
        fetchdata();
    }, [open])

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">View and Update Estimate </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Estimate Number</FormLabel>
                                    <TextField
                                        id="policyNumber"
                                        name="policyNumber"
                                        size="small"
                                        fullWidth
                                        disabled
                                        value={formik.values.policyNumber}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.policyNumber &&
                                            Boolean(formik.errors.policyNumber)
                                        }
                                        helperText={
                                            formik.touched.policyNumber && formik.errors.policyNumber
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Benefit Type</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="benefitType"
                                            name="benefitType"
                                            size="small"
                                            disabled
                                            value={formik.values.benefitType || null} // Preselect the value from claimDate.benefitType
                                            onChange={formik.handleChange}
                                            error={formik.touched.benefitType && Boolean(formik.errors.benefitType)}
                                        >
                                            {policyData && policyData.customerBenefits && policyData.customerBenefits.length > 0 && policyData.customerBenefits.map((ele, index) => (
                                                <MenuItem key={ele._id} value={ele.benefitType}>{ele.benefitType}</MenuItem>
                                            ))
                                            }
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.benefitType && Boolean(formik.errors.benefitType)
                                            }
                                        >
                                            {formik.touched.benefitType && formik.errors.benefitType}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Service Date</FormLabel>
                                    <TextField
                                        id="serviceDate"
                                        name="serviceDate"
                                        size="small"
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            min: policyData && policyData.policyStartDate ? policyData.policyStartDate : "", // Example: Set minimum date
                                            max: policyData && policyData.policyEndDate ? policyData.policyEndDate : "", // Example: Set maximum date
                                          }}
                                        value={formik.values.serviceDate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.serviceDate &&
                                            Boolean(formik.errors.serviceDate)
                                        }
                                        helperText={
                                            formik.touched.serviceDate && formik.errors.serviceDate
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Estimate Date</FormLabel>
                                    <TextField
                                        id="estimateDate"
                                        name="estimateDate"
                                        size="small"
                                        type="date"
                                        fullWidth
                                        value={formik.values.estimateDate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.estimateDate &&
                                            Boolean(formik.errors.estimateDate)
                                        }
                                        helperText={
                                            formik.touched.estimateDate && formik.errors.estimateDate
                                        }
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <FormLabel>Estimate Name</FormLabel>
                                    <TextField
                                        id="estimateName"
                                        name="estimateName"
                                        size="small"
                                        fullWidth
                                        value={cliamIndex + 1}
                                        disabled
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.estimateName &&
                                            Boolean(formik.errors.estimateName)
                                        }
                                        helperText={
                                            formik.touched.estimateName && formik.errors.estimateName
                                        }
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Select Currency</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="currency"
                                            name="currency"
                                            size="small"
                                            value={formik.values.currency}
                                            onChange={formik.handleChange}
                                            error={formik.touched.currency && Boolean(formik.errors.currency)}
                                        >
                                            <MenuItem value="INR">INR</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="EUR">EUR</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.currency && Boolean(formik.errors.currency)
                                            }
                                        >
                                            {formik.touched.currency && formik.errors.currency}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Reserved Amount</FormLabel>
                                    <TextField
                                        id="estimateAmount"
                                        name="estimateAmount"
                                        size="small"
                                        fullWidth
                                        value={formik.values.estimateAmount}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.estimateAmount &&
                                            Boolean(formik.errors.estimateAmount)
                                        }
                                        helperText={
                                            formik.touched.estimateAmount && formik.errors.estimateAmount
                                        }
                                    />
                                </Grid>
                                <Grid item  xs={12} sm={6}>
                                    <FormLabel>Supplier Name</FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="supplierName"
                                            name="supplierName"
                                            size="small"
                                            value={formik.values.supplierName || null}
                                            onChange={formik.handleChange}
                                            error={formik.touched.supplierName && Boolean(formik.errors.supplierName)}
                                        >
                                            <MenuItem value="Cathway Hospital">Cathway Hospital</MenuItem>
                                            <MenuItem value="Local Provider">Local Provider</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.supplierName && Boolean(formik.errors.supplierName)
                                            }
                                        >
                                            {formik.touched.supplierName && formik.errors.supplierName}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>City</FormLabel>
                                    <TextField
                                        id="city"
                                        name="city"
                                        size="small"
                                        fullWidth
                                        // disabled
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.city &&
                                            Boolean(formik.errors.city)
                                        }
                                        helperText={
                                            formik.touched.city && formik.errors.city
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Country</FormLabel>
                                    <TextField
                                        id="country"
                                        name="country"
                                        size="small"
                                        fullWidth
                                        // disabled
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.country &&
                                            Boolean(formik.errors.country)
                                        }
                                        helperText={
                                            formik.touched.country && formik.errors.country
                                        }
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>Claim Status</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="claimStatus"
                                            name="claimStatus"
                                            size="small"
                                            value={formik.values.claimStatus}
                                            onChange={formik.handleChange}
                                            error={formik.touched.claimStatus && Boolean(formik.errors.claimStatus)}
                                        >
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem value="Under Investigation">Under Investigation</MenuItem>
                                            <MenuItem value="Approved">Approved</MenuItem>
                                            <MenuItem value="Denied">Denied</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.claimStatus && Boolean(formik.errors.claimStatus)
                                            }
                                        >
                                            {formik.touched.claimStatus && formik.errors.claimStatus}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} sm={12}>
                                    <FormLabel>Estimate Notes</FormLabel>
                                    <TextField
                                        id="claimNotes"
                                        name="claimNotes"
                                        size="small"
                                        fullWidth
                                        rows={4}
                                        multiline
                                        value={formik.values.claimNotes}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.claimNotes &&
                                            Boolean(formik.errors.claimNotes)
                                        }
                                        helperText={
                                            formik.touched.claimNotes && formik.errors.claimNotes
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                        color="secondary"
                    >
                        Update
                    </Button>
                    {/* <Button
                        type="submit"
                        variant="contained"
                        onClick={deletedata}
                        style={{ textTransform: "capitalize" }}
                        color="error"
                    >
                        Delete
                    </Button> */}
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                        color="error"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ViewEditEstimate