/* eslint-disable react/prop-types */
import * as React from "react";
import { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControl, FormHelperText, FormLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
// eslint-disable-next-line import/no-unresolved
import { apipost } from "src/service/api";
import { toast } from "react-toastify";

const Addestimate = (props) => {
    const { open, handleClose, _id, setUserAction, policyData } = props
    const [selectedBenefitType, setSelectedBenefitType] = useState({});

    // -----------  validationSchema
    const validationSchema = yup.object({
        policyNumber: yup.string().required("Policy Number is required"), 
        claimType: yup.string().required("Claim Type is required"),
        estimateAmount: yup.string().required("Reserved Amount is required"),
        estimateDate: yup.string().required("Estimate Date is required"),
        serviceDate: yup.string().required("Service Date is required"),
        country: yup.string().required("Country Date is required"),
        city: yup.string().required("City Date is required"),
        // claimStatus: yup.string().required("Claim Status is required"),
        claimNotes: yup.string().required("Claim Notes is required"),
        supplierName: yup.string().required("Supplier Name is required"),
        currency: yup.string().required("Currency Name is required"),

    });

    // -----------   initialValues
    const initialValues = {
        policyNumber: props && props.policyData && props.policyData.policyNumber ? props.policyData.policyNumber : "",
        claimType: "",
        estimateAmount: "",
        estimateDate: "",
        serviceDate : "",
        claimStatus: "",
        claimNotes: "",
        supplierName : "",
        currency: "",
        contact_id: _id,
        policy_id: _id,
        amount: "",
        deductibles: "",
        city: "",
        country: ""
    };
    // console.log('initialValues',initialValues)

    const calculateClaimTotal = () => {
        let amt = "";
        if(props.policyData && props.policyData.claims?.length > 0 && selectedBenefitType) {
            const filteredClaims = props.policyData.claims.filter(claim => claim.benefitType === selectedBenefitType.benefitType);

            amt = filteredClaims.reduce((acc, curr) => {
                if(curr && curr.estimateAmount) {
                    return acc + parseInt(curr.estimateAmount, 10);
                }
                return acc;
            }, 0);
            return amt;
        }
        return amt;
    }

     // formik
     const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            addClaim(values)
            // resetForm();
        },
    });

    const totalEstimateAmt = formik.values.estimateAmount + calculateClaimTotal()

    // add claim api
    const addClaim = async (values) => {
        const data = values;
        data.customerBenefitId = selectedBenefitType._id
        data.benefitId = selectedBenefitType.benefitId
        data.benefitType = selectedBenefitType.benefitType
        data.amount = selectedBenefitType.amount
        data.deductibles = selectedBenefitType.deductibles

        if (totalEstimateAmt > parseInt(selectedBenefitType.amount, 10)) {
            formik.setFieldError("estimateAmount", "The total reserved amount cannot exceed the sum insured.");
            return; // Exit function without adding claim
        }

        try {
            const result = await apipost('claim/add', data)
            setUserAction(result)
    
            if (result && result.status === 201) {
                formik.resetForm();
                handleClose();
                toast.success(result.data.message)
            }
        } catch (error) {
            // Handle error if API call fails
            console.error("Error:", error);
            // You can set a general error message here if needed
          }
    }
   

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">Add Estimate </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policy Number</FormLabel>
                                    <TextField
                                        id="policyNumber"
                                        name="policyNumber"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        disabled
                                        value={formik.values.policyNumber}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.policyNumber &&
                                            Boolean(formik.errors.policyNumber)
                                        }
                                        helperText={
                                            formik.touched.policyNumber && formik.errors.policyNumber
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Benefit Type</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="claimType"
                                            name="claimType"
                                            size="small"
                                            value={formik.values.claimType}
                                            onChange={(e) => {
                                                formik.setFieldValue("claimType", e.target.value);
                                                setSelectedBenefitType(props.policyData.customerBenefits[e.target.value]);
                                            }}
                                            error={formik.touched.claimType && Boolean(formik.errors.claimType)}
                                        >
                                            {policyData && policyData.customerBenefits && policyData.customerBenefits.length > 0 && policyData.customerBenefits.map((ele, index) => (
                                                <MenuItem key={ele._id} value={index}>{ele.benefitType}</MenuItem>
                                            ))
                                            }
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.claimType && Boolean(formik.errors.claimType)
                                            }
                                        >
                                            {formik.touched.claimType && formik.errors.claimType}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                {formik.values.claimType !== "" &&
                                    <>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormLabel>Sum Insured</FormLabel>
                                            <TextField
                                                id="sumInsured"
                                                name="sumInsured"
                                                size="small"
                                                disabled
                                                fullWidth
                                                value={selectedBenefitType?.amount}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.amount && Boolean(formik.errors.amount)
                                                }
                                                helperText={formik.touched.amount && formik.errors.amount}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormLabel>Deductible</FormLabel>
                                            <TextField
                                                id="deductibles"
                                                name="deductibles"
                                                size="small"
                                                disabled
                                                fullWidth
                                                value={selectedBenefitType?.deductibles}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.deductibles && Boolean(formik.errors.deductibles)
                                                }
                                                helperText={formik.touched.deductibles && formik.errors.deductibles}
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Service Date</FormLabel>
                                    <TextField
                                        id="serviceDate"
                                        name="serviceDate"
                                        size="small"
                                        type="date"
                                        fullWidth
                                        value={formik.values.serviceDate}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            min: policyData && policyData.policyStartDate ? policyData.policyStartDate : "", // Example: Set minimum date
                                            max: policyData && policyData.policyEndDate ? policyData.policyEndDate : "", // Example: Set maximum date
                                          }}
                                        error={
                                            formik.touched.serviceDate &&
                                            Boolean(formik.errors.serviceDate)
                                        }
                                        helperText={
                                            formik.touched.serviceDate && formik.errors.serviceDate
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Estimate Date</FormLabel>
                                    <TextField
                                        id="estimateDate"
                                        name="estimateDate"
                                        size="small"
                                        type="date"
                                        fullWidth
                                        value={formik.values.estimateDate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.estimateDate &&
                                            Boolean(formik.errors.estimateDate)
                                        }
                                        helperText={
                                            formik.touched.estimateDate && formik.errors.estimateDate
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Select Currency</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="currency"
                                            name="currency"
                                            size="small"
                                            value={formik.values.currency}
                                            onChange={formik.handleChange}
                                            error={formik.touched.currency && Boolean(formik.errors.currency)}
                                        >
                                            <MenuItem value="INR">INR</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="EUR">EUR</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.currency && Boolean(formik.errors.currency)
                                            }
                                        >
                                            {formik.touched.currency && formik.errors.currency}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Reserved Amount</FormLabel>
                                    <TextField
                                        id="estimateAmount"
                                        name="estimateAmount"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.estimateAmount}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.estimateAmount &&
                                            Boolean(formik.errors.estimateAmount)
                                        }
                                        helperText={
                                            formik.touched.estimateAmount && formik.errors.estimateAmount
                                        }
                                    />
                                </Grid>
                                <Grid item  xs={12} sm={6}>
                                    <FormLabel>Supplier Name</FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="supplierName"
                                            name="supplierName"
                                            size="small"
                                            value={formik.values.supplierName || null}
                                            onChange={formik.handleChange}
                                            error={formik.touched.supplierName && Boolean(formik.errors.supplierName)}
                                        >
                                            <MenuItem value="Cathway Hospital">Cathway Hospital</MenuItem>
                                            <MenuItem value="Local Provider">Local Provider</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.supplierName && Boolean(formik.errors.supplierName)
                                            }
                                        >
                                            {formik.touched.supplierName && formik.errors.supplierName}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>City</FormLabel>
                                    <TextField
                                        id="city"
                                        name="city"
                                        size="small"
                                        fullWidth
                                        // disabled
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.city &&
                                            Boolean(formik.errors.city)
                                        }
                                        helperText={
                                            formik.touched.city && formik.errors.city
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Country</FormLabel>
                                    <TextField
                                        id="country"
                                        name="country"
                                        size="small"
                                        fullWidth
                                        // disabled
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.country &&
                                            Boolean(formik.errors.country)
                                        }
                                        helperText={
                                            formik.touched.country && formik.errors.country
                                        }
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>Claim Status</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="claimStatus"
                                            name="claimStatus"
                                            size="small"
                                            value={formik.values.claimStatus}
                                            onChange={formik.handleChange}
                                            error={formik.touched.claimStatus && Boolean(formik.errors.claimStatus)}
                                        >
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem value="Under Investigation">Under Investigation</MenuItem>
                                            <MenuItem value="Approved">Approved</MenuItem>
                                            <MenuItem value="Denied">Denied</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.claimStatus && Boolean(formik.errors.claimStatus)
                                            }
                                        >
                                            {formik.touched.claimStatus && formik.errors.claimStatus}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} sm={12}>
                                    <FormLabel>Estimate Notes</FormLabel>
                                    <TextField
                                        id="claimNotes"
                                        name="claimNotes"
                                        size="small"
                                        fullWidth
                                        rows={4}
                                        multiline
                                        value={formik.values.claimNotes}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.claimNotes &&
                                            Boolean(formik.errors.claimNotes)
                                        }
                                        helperText={
                                            formik.touched.claimNotes && formik.errors.claimNotes
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                        color="secondary"
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                        color="error"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Addestimate