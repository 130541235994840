// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader, FormLabel, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// utils
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
// components
import { useEffect, useState } from 'react';
import { fToNow } from '../../../utils/formatTime';

import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { apiget, apipost } from '../../../service/api';
import FAQ from './Faq';
// ----------------------------------------------------------------------

ClaimUpload.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function ClaimUpload({ title, subheader, list, ...other }) {
    const navigate = useNavigate();
    const validationSchema = yup.object({
        file: yup.string().required("File is required")
    });
    const userid = localStorage.getItem('user_id');


    const initialValues = {
        file: "",
        createdBy: userid
    };
    const [userAction, setUserAction] = useState(null);

      // add contact api
      const fileUpload = async (values) => {
        const data = new FormData()
        data.append("name", values.file.name)
        data.append("file", values.file)
        data.append("fileName", values.fileName)
        data.append("createdBy", values.createdBy)
    
        const result = await apipost('document/upload', data)
        setUserAction(result)
    
        if (result && result.status === 200) {
            formik.resetForm();
            // toast.success(result.data.message)
        }
    }
    
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            fileUpload(values)
        },
    });

    const handleProceed = () => {
        navigate('/dashboard/policy/view/65eee397c8228f729eef4b00')
    }

  return (
    <Card {...other}>
      <CardHeader title="Claim Amount - $5000" />
      {/* <Scrollbar> */}
        <Stack spacing={3} sx={{ p: 3, pr: 0, flexShrink: 0 }}>
        {/* <Grid item xs={12} sm={6} md={6}> */}
            <FAQ title="" subheader="" />
            {/* </Grid> */}
        </Stack>
        <Divider />

        {/* <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
        <Grid item xs={12} sm={6} md={6}>
            <FormLabel>Upload PB</FormLabel>
            <TextField
                id="file"
                name="file"
                size="small"
                maxRows={10}
                fullWidth
                type="file"
                multiple
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => {
                    formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
                error={
                    formik.touched.file &&
                    Boolean(formik.errors.file)
                }
                helperText={
                    formik.touched.file && formik.errors.file
                }
            />
            </Grid>
        </Stack>
        <Divider />

        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Upload M.B.</FormLabel>
                <TextField
                    id="file"
                    name="file"
                    size="small"
                    maxRows={10}
                    fullWidth
                    type="file"
                    multiple
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    error={
                        formik.touched.file &&
                        Boolean(formik.errors.file)
                    }
                    helperText={
                        formik.touched.file && formik.errors.file
                    }
                />
            </Grid>
        </Stack>
        <Divider />

        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Remarks</FormLabel>
                <TextField
                    id="address"
                    name="address"
                    size="small"
                    multiline
                    fullWidth
                    rows={4}
                    // value={formik.values.address}
                    // onChange={formik.handleChange}
                    // error={
                    // formik.touched.address && Boolean(formik.errors.address)
                    // }
                    // helperText={formik.touched.address && formik.errors.address}
                />
            </Grid>
        </Stack>

        <Divider />

        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            <Grid item xs={12} sm={6} md={6}>
                <Button
                    type="submit"
                    variant="contained"
                    // onClick={formik.handleSubmit}
                    style={{ textTransform: "capitalize" }}
                // startIcon={<FiSave />}
                >
                    Submit
                </Button>
            </Grid>
        </Stack> */}

    <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            <Grid item xs={12} sm={6} md={6}>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleProceed}
                    style={{ textTransform: "capitalize" }}
                // startIcon={<FiSave />}
                >
                    Proceed
                </Button>
            </Grid>
        </Stack>
        
{/* </Scrollbar> */}
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { image, title, description, postedAt } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box component="img" alt={title} src={image} sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
          {title}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {fToNow(postedAt)}
      </Typography>
    </Stack>
  );
}