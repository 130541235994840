/* eslint-disable react/prop-types */
import * as React from "react";
import { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel,FormControl, Select, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { apipost } from "../../../service/api";

const Add = (props) => {
    const { open, handleClose, setUserAction, _id } = props
    const userid = localStorage.getItem('user_id')
    const [selectedEstimate, setSelectedEstimate] = useState({});
    // -----------  validationSchema
    const validationSchema = yup.object({
        file: yup.string().required("File is required"),
        fileName: yup.string().required("File Name is required"),
        invoiceAmount: yup.string().required("Invoice Amount is required"),
        estimateNo: yup.string().required("Estimate No is required"),
        // estimateAmount: yup.string().required("Reserved Amount is required"),
        // supplierName : yup.string().required("Supplier Name is required"),
        // benefitName : yup.string().required("Benefit Name is required"),
    });

    // -----------   initialValues
    const initialValues = {
        file: "",
        fileName: "",
        estimateNo : "",
        estimateAmount : "",
        invoiceAmount : "",
        supplierName : "",
        benefitName : "",
        policy_id: _id,
        createdBy: userid
    };

    // add contact api
    const fileUpload = async (values) => {
        const data = new FormData()
        data.append("name", values.file.name)
        data.append("file", values.file)
        data.append("fileName", values.fileName)
        // data.append("estimateAmount", values.estimateAmount)
        // data.append("supplierName", values.supplierName)
        // data.append("benefitName", values.benefitName)
        // data.append("estimateNo", values.estimateNo)
        data.append("invoiceAmount", values.invoiceAmount)
        data.append("policy_id", values.policy_id)
        data.append("createdBy", values.createdBy)
        data.append("estimateId", selectedEstimate._id)

        if (totalInvoiceAmt > parseInt(selectedEstimate?.estimateAmount, 10)) {
            formik.setFieldError("invoiceAmount", "The total invoice amount cannot exceed the reserved amount.");
            return;
        }

        const result = await apipost('claim/estimateDoc/upload', data)
        setUserAction(result)

        if (result && result.status === 200) {
            formik.resetForm();
            handleClose();
            // toast.success(result.data.message)
        }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            fileUpload(values)
        },
    });

    const totalInvoiceAmt = parseInt(formik.values.invoiceAmount, 10) + selectedEstimate.totalInvoiceAmount;

    const onEstimateChange = (value) => {
        setSelectedEstimate(value);
    }
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">Add New </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>Select Estimate No</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="estimateNo"
                                            name="estimateNo"
                                            size="small"
                                            value={formik.values.estimateNo}
                                            onChange={(e) => {
                                                formik.setFieldValue("estimateNo", e.target.value);
                                                setSelectedEstimate(props.policyData.claims[e.target.value])
                                            }}
                                            error={formik.touched.documentName && Boolean(formik.errors.estimateNo)}
                                        >
                                            {
                                                props.policyData && props.policyData &&  props.policyData.claims && props.policyData.claims.length > 0 &&
                                                props.policyData.claims.map((val, index) => {
                                                    return(
                                                        <MenuItem value={index}>{`Estimate ${index + 1}`}</MenuItem>            
                                                    )
                                                })
                                            }
                                            
                                            {/* <MenuItem value="Doc 2">Estimate 2</MenuItem>
                                            <MenuItem value="Doc 3">Estimate 3</MenuItem> */}
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.estimateNo && Boolean(formik.errors.estimateNo)
                                            }
                                        >
                                            {formik.touched.estimateNo && formik.errors.estimateNo}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            {formik.values.estimateNo!=="" &&
                                <>
                                 <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Benefit Name</FormLabel>
                                    <TextField
                                        id="benefitName"
                                        name="benefitName"
                                        size="small"
                                        disabled
                                        fullWidth
                                        value={selectedEstimate?.benefitType}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.benefitName && Boolean(formik.errors.benefitName)
                                        }
                                        helperText={formik.touched.benefitName && formik.errors.benefitName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Reserved Amount</FormLabel>
                                    <TextField
                                        id="estimateAmount"
                                        name="estimateAmount"
                                        size="small"
                                        disabled
                                        fullWidth
                                        value={selectedEstimate?.estimateAmount}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.estimateAmount && Boolean(formik.errors.estimateAmount)
                                        }
                                        helperText={formik.touched.estimateAmount && formik.errors.estimateAmount}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Supplier Name</FormLabel>
                                    <TextField
                                        id="supplierName"
                                        name="supplierName"
                                        disabled
                                        size="small"
                                        fullWidth
                                        value={selectedEstimate?.supplierName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.supplierName && Boolean(formik.errors.supplierName)
                                        }
                                        helperText={formik.touched.supplierName && formik.errors.supplierName}
                                    />
                                </Grid>
                                </>
                            }
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Upload File</FormLabel>
                                <TextField
                                    id="file"
                                    name="file"
                                    size="small"
                                    maxRows={10}
                                    fullWidth
                                    type="file"
                                    multiple
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => {
                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                    error={
                                        formik.touched.file &&
                                        Boolean(formik.errors.file)
                                    }
                                    helperText={
                                        formik.touched.file && formik.errors.file
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Invoice Amount</FormLabel>
                                <TextField
                                    id="invoiceAmount"
                                    name="invoiceAmount"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    value={formik.values.invoiceAmount}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.invoiceAmount && Boolean(formik.errors.invoiceAmount)
                                    }
                                    helperText={formik.touched.invoiceAmount && formik.errors.invoiceAmount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>FileName</FormLabel>
                                <TextField
                                    id="fileName"
                                    name="fileName"
                                    size="small"
                                    fullWidth
                                    value={formik.values.fileName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.fileName && Boolean(formik.errors.fileName)
                                    }
                                    helperText={formik.touched.fileName && formik.errors.fileName}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Add