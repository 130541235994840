import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from "yup";

import CircularProgress from '@mui/material/CircularProgress';
import { apipost } from '../../../service/api';
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

export default function CustomerLoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLogin] = useState(false)
  const [selectedPhone, setSelectedPhone] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const initialValues = {
    policyNo:"",
    passportNo: "",
    mobile: "",
    otp: ""
  }

  // -----------  validationSchema
//   const validationSchema = yup.object({
//     policyNo: yup.string().required("Policy No is required"),
//     // passportNo: yup.string().required("Passport No is required"),
//     // mobile: yup.string().required("Mobile No is required"),
//     // otp: yup.string().required("OTP is required")
//   });

const [validationSchema, setValidationSchema] = useState(() => 
    yup.object({
      policyNo: yup.string().required("Policy No is required"),
      passportNo: yup.string().required("Passport No is required"),
      // mobile: yup.string().required("Mobile No is required"),
      // otp: yup.string().required("OTP is required")
    })
  );

  const sendOtp = async () => {
    const postData = {
        number: formik.values.mobile,
        source: 'website',
        random_id: 'xrmvX'
      };
  
      try {
        const response = await fetch('https://gomechanic.app/api/v2/send_otp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)
        });
  
        if (!response.ok) {
          throw new Error('Failed to send OTP');
        }
  
        const responseData = await response.json();
        setResponseMessage('OTP sent successfully:', responseData.message);
      } catch (error) {
        console.error('Error sending OTP:', error);
        setResponseMessage('Error sending OTP:', error.message);
      }
    };

    const Adddata = async (values) => {
        setIsLogin(true);
      
        // Check if OTP is not "0000"
        if (values.mobile && values.otp) {
            // Check if OTP is not "0000"
            if (values.otp !== "0000") {
              // Set error message for OTP field
              formik.setFieldError("otp", "Invalid OTP. Please enter a valid OTP.");
              setIsLogin(false);
              return; // Exit function without making API call
            }
          }
      
        // Make the API call only if OTP is "0000"
        const data = {
          emailAddress: "akshay@gmail.com",
          password: "admin123"
        };
      
        try {
          const result = await apipost("user/login", data);
      
          if (result && result.status === 200) {
            localStorage.setItem("user", JSON.stringify(result?.data?.user));
            localStorage.setItem("user_id", result?.data?.user?._id);
            localStorage.setItem("userRole", result?.data?.user?.role);
            localStorage.setItem("policyNo", formik.values.policyNo);
            localStorage.setItem("passportNo", formik.values.passportNo);
            localStorage.setItem("mobile", formik.values.mobile);
            localStorage.setItem("otp", formik.values.otp);
      
            if (
              result &&
              result.data &&
              result.data.user &&
              result?.data?.user?.role === "user"
            ) {
              navigate("/dashboard/policy");
            } else {
              navigate("/dashboard/app");
            }
          } else {
            navigate("/dashboard/app");
          }
        } catch (error) {
          // Handle error if API call fails
          console.error("Error:", error);
          // You can set a general error message here if needed
        }
      
        setIsLogin(false);
      };
      
      

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      Adddata(values)
    },
  });

  const updateValidationSchema = () => {

    // console.log("formik.values.policyNo",formik.values.policyNo);
    const newValidationSchema = yup.object().shape({
      policyNo: formik.values.policyNo
        && yup.string().required("Policy No is required"),
      passportNo: formik.values.passportNo
        && yup.string().required("Passport No is required"),
    });
    setValidationSchema(newValidationSchema);
  };

  useEffect(() => {
    updateValidationSchema();
  }, [formik.values]);

  useEffect(() => {
    if (selectedPhone.length === 10) {
      // Update validation schema to make OTP field required
      setValidationSchema(
        yup.object({
          ...validationSchema.fields,
          otp: yup.string().required("OTP is required")
        })
      );
      sendOtp();
    } else {
      // Revert validation schema to make OTP field optional
      setValidationSchema(
        yup.object({
          ...validationSchema.fields,
          otp: yup.string()
        })
      );
    }
  }, [selectedPhone]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3} mb={2}>
          <TextField
            name="policyNo"
            label="Policy No"
            value={formik.values.policyNo}
            onChange={formik.handleChange}
            error={
              formik.touched.policyNo &&
              Boolean(formik.errors.policyNo)
            }
            helperText={
              formik.touched.policyNo && formik.errors.policyNo
            }
          />

          <TextField
            name="passportNo"
            label="Passport No"
            value={formik.values.passportNo}
            onChange={formik.handleChange}
            error={
              formik.touched.passportNo &&
              Boolean(formik.errors.passportNo)
            }
            helperText={
              formik.touched.passportNo && formik.errors.passportNo
            }
          />

          <TextField
            name="mobile"
            label="Mobile"
            value={formik.values.mobile}
            // onChange={formik.handleChange}
            onChange={(e) => {
                formik.setFieldValue("mobile", e.target.value);
                setSelectedPhone(e.target.value);
            }}
            error={
              formik.touched.mobile &&
              Boolean(formik.errors.mobile)
            }
            inputProps={{ maxLength: 10 }}
            helperText={
              formik.touched.mobile && formik.errors.mobile
            }
          />

          {selectedPhone.length === 10 &&  <TextField
            name="otp"
            label="Otp"
            value={formik.values.otp}
            onChange={formik.handleChange}
            error={
              formik.touched.otp &&
              Boolean(formik.errors.otp)
            }
            helperText={
              formik.touched.otp && formik.errors.otp
            }
          />}

        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!!isLoading}>
          {isLoading ? <CircularProgress /> : 'Login'}
        </LoadingButton>
      </form>
    </>
  );
}
