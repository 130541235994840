/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import "./Benefits.css";
import { DataGrid } from '@mui/x-data-grid'
import React, { useState, useEffect } from 'react'
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import NotesIcon from "@mui/icons-material/Notes";
import { toast } from "react-toastify";
import { apipost } from "../../service/api";
import Addnotes from './AddNotes';
import ViewNote from './ViewEdit';
import TableStyleTwo from '../TableStyleTwo';

const Benefits = ({ rows, toggleVisibilityNotes, isVisibleNotes, _id, setUserAction, method, selectedBenefits, setSelectedBenefits, policyData, userRole }) => {

    const [openNoteView, setOpenNoteView] = useState(false);
    const [noteId, setNoteId] = useState('');
    const handleOpenNoteView = () => setOpenNoteView(true)
    const handleCloseNoteView = () => setOpenNoteView(false);
    const user = localStorage.getItem('userRole');
    const userid = localStorage.getItem('user_id');
    const policyNo = localStorage.getItem('policyNo');
    const userInfo = JSON.parse(localStorage.getItem('user'));
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    // const [selectedBenefits, setSelectedBenefits] = useState([]);
    // const [role, setRole] = useState(userInfo.emailAddress === "rishabh@gmail.com" ? true : false)
    const email = userInfo.emailAddress === "rishabh@gmail.com";
    const arr = [
        {
            "_id" : 1,
            "name": "Medical Expenses including Evacuation & Repatriation",
            "amount" : "100000",
            "deductibles" : "100"
        },
        {
            "_id" : 2,
            "name": "Personal Accident",
            "amount" : "100000",
            "deductibles" : "100"
        },
        {
            "_id" : 3,
            "name": "Loss of passport",
            "amount" : "100000",
            "deductibles" : "100"
        },
        {
            "_id" : 4,
            "name": "Golfer's Hole-In-One",
            "amount" : "100000",
            "deductibles" : "100"
        },
    ]
    const columns = [
        {
            field: "benefitType",
            headerName: "Benefit Name",
            // cellClassName: "name-column--cell name-column--cell--capitalize",
            flex: 1,
        },
        {
            field: "amount",
            headerName: "Sum Insured",
            flex: 1,
            valueGetter: (params) => {
                const amount = params.value; // Get the amount value from the row
                return `$ ${amount}`; // Append "US$" to the amount and return
            },
        },
        {
            field: "deductibles",
            headerName: "Deductibles",
            flex: 1,
            valueGetter: (params) => {
                const amount = params.value; // Get the amount value from the row
                return `$ ${amount}`; // Append "US$" to the amount and return
            },
        }

    ];
    const columns1 = [
        {
            field: "_id",
            headerName: "Claim Id",
            // cellClassName: "name-column--cell name-column--cell--capitalize",
            flex: 1,
        },
        {
            field: "benefitType",
            headerName: "Benefit Name",
            // cellClassName: "name-column--cell name-column--cell--capitalize",
            flex: 1,
        },
        {
            field: "amount",
            headerName: "Sum Insured",
            flex: 1,
            valueGetter: (params) => {
                const amount = params.value; // Get the amount value from the row
                return `$ ${amount}`; // Append "US$" to the amount and return
            },
        },
        {
            field: "deductibles",
            headerName: "Deductibles",
            flex: 1,
            valueGetter: (params) => {
                const amount = params.value; // Get the amount value from the row
                return `$ ${amount}`; // Append "US$" to the amount and return
            },
        }

    ];
    const valueExistsForKey = (array, key, valueToCheck) => {
        return array.some(obj => {
            return obj[key] === valueToCheck;
          });
      };
    const getRowClassName = (params) => {
        if (params.row.benefitId) {
            if(policyData && policyData.customerBenefits &&  policyData.customerBenefits.length > 0){
                const keyToCheck = 'benefitId';
                const exists = valueExistsForKey(policyData.customerBenefits, keyToCheck, params.row.benefitId);
                if(exists){
                    return 'disabled-row';
                }
            }
        }
        return ''; 
      };
    const handleSelectionChange = (selectionModel) => {
        let rowData = [];
        if(selectionModel.length > 0){
             setSelectedRowIds(selectionModel);    
             rowData = selectionModel.map((index) => policyData.benefitList[index -1]);
            //  selectionModel.filter((index) => {
            //     const selectedObject = arr[index];
            //     console.log(!selectedBenefits.find((obj) => obj.id === selectedObject.id),'selectedObject',selectedObject)
                
            //     if(!selectedBenefits.find((obj) => obj.id === selectedObject.id)){
            //         rowData.push(selectedObject)
            //     }
               
            //     return !selectedBenefits.find((obj) => obj.id === selectedObject.id);
            //   });
            //  console.log('here',rowData)
        }else{
            console.log('there')
            setSelectedRowIds(selectionModel);    
            rowData = []
        }
        console.log(rowData,'selectionModel',selectionModel);
        
        setSelectedBenefits([...rowData])
    };


    // open note model
    const [openNote, setOpenNote] = useState(false);
    const handleOpenNote = () => {
        setOpenNote(true);
    };
    const handleCloseNote = () => setOpenNote(false);

    const removeKeyFromObjects = (array, keyToRemove) => {
        return array.map(obj => {
          const newObj = { ...obj };
          delete newObj[keyToRemove];
          return newObj;
        });
      };
    
    const addMultipleClaim = async (values) => {
        if(selectedBenefits.length === 0 ){
            alert('Please select benefits to raise claim');
            return;
        }
        // const data = selectedBenefits;
        // const newArray = removeKeyFromObjects(data, '_id');
        // policy_id : _id
        
        const data = selectedBenefits.map((obj) => ({ ...obj, 
            ...{
              'createdBy': userid,
              'policyNumber' : policyNo,
              'policy_id' : _id
             } 
          }))
        const newArray = removeKeyFromObjects(data, '_id');
        // const payload = {'customer_benefits' : data, 'createdBy': userid, 'policyNumber' : policyNo};
  
        const result = await apipost('claim/addmany', newArray)
        

        if (result && result.status === 201) {
            setUserAction(result)
            toast.success(result.data.message)
            setSelectedRowIds([])
            setSelectedBenefits([])
        }
    }

    // useEffect(() => {
    //     // addMultipleClaim(selectedBenefits)
    // },[selectedBenefits])
    return (
        <div>
            {/* VIew model */}
            <ViewNote open={openNoteView} handleClose={handleCloseNoteView} id={noteId} setUserAction={setUserAction} />

            {/* Add Notes */}
            <Addnotes open={openNote} handleClose={handleCloseNote} _id={_id} setUserAction={setUserAction} method={method} />

            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityNotes}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleNotes ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <NotesIcon />
                            {userRole === "user" ?
                                <Typography variant="h5">Plan Benefits (Your benefit wise sum insured is as follows)</Typography>
                                : <Typography variant="h5">Raised Claim</Typography>
                            }
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleNotes && selectedBenefits.length > 0 && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={addMultipleClaim}
                                >
                                    Raise Claim
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisibleNotes &&
                <TableStyleTwo>
                    <Box width="100%">
                        {
                            user === "user" ? 
                            <DataGrid
                                rows={policyData?.benefitList || []}
                                columns={columns}
                                getRowId={row => row._id}
                                columnHeaderHeight={40}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                // getRowClassName={getRowClassName}
                            />
                            :
                            <DataGrid
                                rows={policyData?.customerBenefits || []}
                                columns={columns1}
                                getRowId={row => row._id}
                                columnHeaderHeight={40}
                            />
                        }
                        
                    </Box>
                </TableStyleTwo>

            }
        </div>
    )
}

export default Benefits
