/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import HistoryIcon from "@mui/icons-material/History";
import AddIcon from "@mui/icons-material/Add";
import Addclaim from "./Addclaim";
import Addestimate from "./Addestimate";
import ViewClaim from "./ViewEdit";
import ViewEditEstimate from "./ViewEditEstimate";
import TableStyleTwo from "../TableStyleTwo";

const Claim = ({ rows, toggleVisibilityClaim, isVisibleClaim, _id, setUserAction, policyData }) => {

  const [claimId, setClaimId] = useState('');
  const [cliamIndex, setClaimIndex] = useState('');
  const [openClaimView, setOpenClaimView] = useState(false)
  const [openEstimateView, setOpenEstimateView] = useState(false)
  
  const handleOpenView = () => {
    if(email)
      setOpenClaimView(true);
    else
      setOpenEstimateView(true);
  }
  const handleCloseClaimView = () => {
    setOpenClaimView(false);
    setOpenEstimateView(false);
  }
  const user = localStorage.getItem('userRole');
  const userInfo = JSON.parse(localStorage.getItem('user'));
  // const [role, setRole] = useState(userInfo.emailAddress === "rishabh@gmail.com" ? true : false)
  const email = userInfo.emailAddress === "rishabh@gmail.com";

  
  const columns = [
    {
      field: "_id",
      headerName: "Estimate No",
      cellClassName: "name-column--cell",
      flex: 2,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          const rowIndex = rows.findIndex(row => row._id === params.row._id);
          setClaimIndex(rowIndex);
          handleOpenView();
          setClaimId(params?.row?._id)
        };

        return (
          <Box onClick={handleFirstNameClick}>
            {params.value ? params.value.substring(params.value.length - 6) : "NA"}
          </Box>
        );
      }
    },
    {
      field: "benefitType",
      headerName: "Benefit Type",
      flex: 2,
    },
    { field: "estimateAmount", headerName: "Reserved Amount", flex: 2 },
    {
      field: "estimateDate",
      headerName: "Estimate Date",
      flex: 2,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
    { field: "totalInvoiceAmount", headerName: "Claim Amount", flex: 2 },
    {
      field: "claimDate",
      headerName: "Claim Date",
      flex: 2,
      valueFormatter: (params) => {
        if(params.value){
          const date = new Date(params.value);
          return date.toLocaleString();
        }
        return ''
      },
    },
    { field: "approvedAmount", headerName: "Approved Amount", flex: 2 },
    // { field: "claimStatus", headerName: "Claim Status", flex: 2 },
    {
      field: "claimStatus",
      headerName: "Claim Status",
      flex: 2,
      renderCell: (params) => {
        return (
          <Box style={{
            background : params.value === "Approved" ? 'green' : params.value === "Denied" ? 'red' : params.value === 'Under Investigation' ? 'lightblue' : '#dcdc1c',
            borderRadius : '20px',
            color : '#fff',
            fontSize : '12px',
            fontWeight : '500',
            width : '100px',
            padding :  params.value === "Approved" ? '10px 8px' : '10px 14px',
            marginLeft: params.value === 'Under Investigation' ? '' : '',
            textAlign : "center"
          }}>
            {params.value === 'Under Investigation' ?  'In Progess' : params.value ? params.value : 'Pending'}
          </Box>
        );
      }
    },
  ];

  const columns1 = [
    {
      field: "_id",
      headerName: "Estimate No",
      cellClassName: "name-column--cell",
      flex: 2,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          const rowIndex = rows.findIndex(row => row._id === params.row._id);
          setClaimIndex(rowIndex);
          handleOpenView();
          setClaimId(params?.row?._id)
        };

        return (
          <Box onClick={handleFirstNameClick}>
            {params.value ? params.value.substring(params.value.length - 6) : "NA"}
          </Box>
        );
      }
    },
    {
      field: "benefitType",
      headerName: "Benefit Name",
      flex: 2,
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      flex: 2,
    },
    { field: "estimateAmount", headerName: "Reserved Amount", flex: 2 },
    {
      field: "estimateDate",
      headerName: "Estimate Date",
      flex: 2,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
    { field: "totalInvoiceAmount", headerName: "Claim Amount", flex: 2 },
    { field: "approvedAmount", headerName: "Approved Amount", flex: 2 },
    // { field: "claimStatus", headerName: "Claim Status", flex: 2 },
    {
        field: "claimStatus",
        headerName: "Claim Status",
        flex: 2,
        renderCell: (params) => {
          return (
            <Box style={{
              background : params.value === "Approved" ? 'green' : params.value === "Denied" ? 'red' : params.value === 'Under Investigation' ? 'lightblue' : '#dcdc1c',
              borderRadius : '20px',
              color : '#fff',
              fontSize : '12px',
              fontWeight : '500',
              padding :  params.value === "Approved" ? '10px 8px' : '10px 14px',
              marginLeft: params.value === 'Under Investigation' ? '' : '',
              textAlign : "center"
            }}>
              {params.value === 'Under Investigation' ?  'In Progess' : params.value ? params.value : 'Pending'}
            </Box>
          );
        }
      },
    {
      field: "claimDate",
      headerName: "Claim Date",
      flex: 2,
      valueFormatter: (params) => {
        if(params.value){
          const date = new Date(params.value);
          return date.toLocaleString();
        }
        return ''
      },
    }
  ];

  // open Claim model
  const [openClaim, setOpenClaim] = useState(false);
  const [openEstimate, setOpenEstimate] = useState(false);
  const handleOpenClaim = () => {
    if(email)
      setOpenClaim(true);
    else
      setOpenEstimate(true);
  };
  const handleCloseClaim = () => {
    setOpenClaim(false)
    setOpenEstimate(false);
  };
  return (
    <div>

      {/* View Claim */}
      <ViewClaim open={openClaimView} handleClose={handleCloseClaimView} id={claimId} setUserAction={setUserAction}  policyData={policyData}/>

      <ViewEditEstimate open={openEstimateView} handleClose={handleCloseClaimView} id={claimId} setUserAction={setUserAction} policyData={policyData} cliamIndex={cliamIndex} />
      
      {/* Add Claim */}
      <Addclaim open={openClaim} handleClose={handleCloseClaim} _id={_id} setUserAction={setUserAction} policyData={policyData} user={email}/>

      <Addestimate open={openEstimate} handleClose={handleCloseClaim} _id={_id} setUserAction={setUserAction} policyData={policyData} user={email}/>

      <Box style={{ cursor: "pointer" }} p={2}>
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Button
                onClick={toggleVisibilityClaim}
                color="secondary"
                variant="contained"
                sx={{ width: "28px", minWidth: "0px", padding: "0px" }}
              >
                {isVisibleClaim ? <RemoveIcon /> : <AddIcon />}
              </Button>
              <HistoryIcon />
              <Typography variant="h6">{email ? 'Claim History' : 'Estimate History'}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
              {isVisibleClaim && user === 'admin' && !email && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenClaim}
                >
                  {email ? 'Claim' : 'Estimate'}
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Box>
      {
        isVisibleClaim &&
        <TableStyleTwo>
          <Box width="100%">
            {
              email ? 
              <DataGrid
                rows={rows}
                columns={columns}
                getRowId={row => row._id}
                columnHeaderHeight={40}
              />
              :
              <DataGrid
                rows={rows}
                columns={columns1}
                getRowId={row => row._id}
                columnHeaderHeight={40}
              />
            }
            
          </Box>
        </TableStyleTwo>
      }
    </div>
  );
};

export default Claim;
